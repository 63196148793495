import React from "react"
import Layout from "../components/Layout"

const IndexPage = () => (
    <Layout>
        <div className="content-header">
            <div className="wrapper">
                <h1 className="page-title">Om <span>Casinolabbet</span></h1>
                <p className="page-description">Casinolabbet.se är ett projekt som försöker förbättra och förändra </p>
            </div>
        </div>

        <div className="content top">
            <div className="block-layout">
                <div className="text full">
                    <h2 className="header-margin">Casinohem hjälper dig hitta rätt online casino</h2>
                    <p>Casinolabbet.se ägs och drivs av <a href="https://www.allabolag.se/5592799000/icsu-media-ab" target="_blank" className="external">ICSU Media AB</a>. </p>
                </div>
                <div className="text half left">
                    <h3>Vision och mål</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id orci eu libero pharetra lacinia vitae sed ipsum. Proin nec sodales urna. Nam fermentum augue feugiat ex commodo, non luctus velit aliquam. Maecenas velit ipsum, posuere nec velit ut, lacinia lacinia elit. Nam lacus metus, facilisis.</p>
                </div>
                <div className="text half right">
                    <h3>Disclaimer</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id orci eu libero pharetra lacinia vitae sed ipsum. Proin nec sodales urna. Nam fermentum augue feugiat ex commodo, non luctus velit aliquam. Maecenas velit ipsum, posuere nec velit ut, lacinia lacinia elit. Nam lacus metus, facilisis.</p>
                </div>
                <div className="text half left">
                    <h3>Kontakt</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id orci eu libero pharetra lacinia vitae sed ipsum. Proin nec sodales urna. Nam fermentum augue feugiat ex commodo, non luctus velit aliquam. Maecenas velit ipsum, posuere nec velit ut, lacinia lacinia elit. Nam lacus metus, facilisis.</p>
                </div>
                <div className="text half right">
                    <h3>Kontakt</h3>
                    <ul>   
                        <li>ICSU Media AB</li>
                        <li>E-post: <a href = "mailto: info@casinolabbet.se">info@casinolabbet.se</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </Layout>   
)

export default IndexPage 